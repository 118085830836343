import React from "react"
import {graphql} from "gatsby"
import Layout from "../../components/layout"
import PostCard from "../../components/post-card"

export default function NewsForestPage({
    data: {
      allMarkdownRemark: { edges }
    }
  }) {
    const Posts = edges.map(edge => {
        
        const post = edge.node;
  
        return (
          <div key = {post.id}>
            <PostCard 
            title = {post.frontmatter.title} 
            date = {post.frontmatter.date} 
            excerpt = {post.excerpt} 
            slug = {post.frontmatter.slug} 
            />
            <br/>
          </div>
            
        )
    });
    return(
        <Layout>
            <div className="font-montserrat text-4xl text-red-dark">News about the FOREST project</div>
            <br/>
            {Posts}
        </Layout>
    )
}

export const query = graphql`
query {
  allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, filter: {frontmatter: {tag: {eq: "FORESTprojectEU"}}}) {
    edges {
      node {
        frontmatter {
          date(formatString: "DD MMMM YYYY")
          slug
          title
        }
        excerpt(pruneLength: 250)
      }
    }
  }
}
`