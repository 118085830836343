/* eslint react/prop-types: 0 */
import { Link } from "gatsby";
import React from "react";
import {links} from "../data";

export default function Header() {


  return (
    <header className="bg-white shadow">
      <div className="flex flex-wrap items-center justify-between max-w-5xl p-4 mx-auto md:p-8">
        <Link to="/">
          <h1 className="flex items-center text-black no-underline">
            <span className="text-4xl font-montserrat tracking-tight">
              anfmr
            </span>
          </h1>
        </Link>

        <nav
          className= "md:block md:flex md:items-center w-full md:w-auto"
        >
          {
          links.map((link) => (
            <Link
              className="block mt-4 font-montserrat hover:text-red-dark no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              to={link.route}
            >
            {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

