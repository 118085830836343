import React from "react"

export default function Author() {
    return(
        <div className="flex flex-col justify-center">
            <p className="text-white text-md font-montserrat">
              Created by <a className="hover:font-bold" href = "https://github.com/dianapaula19" target="_blank" rel="noopener noreferrer">Diana Băcîrcea</a>
            </p>
        </div>
    )
}