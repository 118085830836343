import React from "react"
import Author from "../components/author"

export default function Footer() {
    return(
        <footer className="bg-red-dark">
        <nav className="flex flex-row justify-between max-w-5xl p-4 mx-auto text-sm">
          <Author />
          <p className="text-white">@ 2022 ANFMR. All rights reserved</p>
        </nav>
      </footer>
    )
}