import React from "react"

export default function PostCard({slug, title, date, excerpt, tag, path}) {
    return(
        
        <div className="shadow-md">
            <div className = "p-4">
                <a href = {slug} target="_blank" rel="noopener noreferrer">
                    <div className="font-lora font-bold text-xl hover:text-red-dark">{title}</div>
                </a>
                <div className="font-lora text-lg">{date}</div>
                <p className="text-gray-700 font-lora">{excerpt}</p>
                <br/>
                {tag && 
                (<a href={path}>
                    <span class="inline-block font-montserrat bg-gray-200 hover:bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#{tag}</span>
                </a>)
                }
            </div>
        </div>
    )
}