export const partnerships = [
    {
        title: 'Initiator of the Romanian-Swedish partnership "Women Resources Centres"',
        year: '2016'
    },
    {
        title: 'Romanian delegate at European Rural Parliament',
        year: '2015'
    },
    {
        title: 'Association for Local Democracy Agencies',
        year: '2015'
    },
    {
        title: 'Women of Europe for a Common Future',
        year: '2014'
    },
    {
        title: 'Local Action Group "Muscelean and European"',
        year: '2010'
    },
    {
        title: 'National Association for Rural Ecological and Cultural Tourism',
        year: '2008'
    },
    {
        title: 'Monitoring Commeetee for Rural Development National Plan',
        year: '2007-2013'
    },
    {
        title: 'Coordonation National Commeetee for Rural Development National Network',
        year: '2007'
    },
];

export const activities = [
    {
        name: 'Aware campaigns on rural women’s role in local communities',
        projects: ['founding member of Local Action Group "Muscelean and European"',
                    'local project "Green Week"'
                    ]
    },
    {
        name: 'Humanitarian campaign "A Romanian in need is a friend, indeed"',
        projects: []
    },
    {
        name: 'Handicrafts workshops and fairs',
        projects: []
    },
    {
        name: 'Cultural events',
        projects: ['"The Cheese Story"', '"Nedeea Musceleana"']
    },
]

export const trainings = [
    {
        title: 'START YOUR OWN BUSSINESS',
        number: 'POSDRU/D.2/3.1/S/52140',
        beneficiary: 'CCIR-ADAF Romania',
        localPartner: 'RWNA',
        objectivies: [
            'rural women’s entrepreneurial abilities development', 
            'private initiatives support in Arges county rural areas', 
            '20 rural women training in business plan drafting']
    },
]

export const links = [
    
      {
        route: '/projects/cyberseniors',
        title: 'projects',
      },
      { 
        route: '/activities',
        title: 'activities',
      },
      {
        route: '/gallery',
        title: 'gallery',
      },
      {
        route: '/news',
        title: 'news',
      },
      {
        route: '/contact',
        title: 'contact',
      }
]

export const projectLinks = [
    {
        route: '/projects/cyberseniors',
        title: 'CYBERSENIORS'
    },
    
    {
        route: '/projects/value',
        title: 'VALUE'
    },

    {
        route: '/projects/forest',
        title: 'FOREST'
    },
    {
        route: '/projects/recov',
        title: 'RECOV'
    },
    {
        route: '/projects/smart-participation',
        title: 'SMART PARTICIPATION'
    },
    {
        route: '/projects/femme',
        title: 'FEMME'
    },
    {
        route: '/projects/smart-generation',
        title: 'SMART GENERATION'
    },
    {
        route: '/projects/women',
        title: 'WOMEN'
    },
    {
        route: '/projects/cultures-of-water',
        title: 'CULTURES OF WATER - SOURCE ENCOUNTERS'
    },
    {
        route: '/projects/peer',
        title: 'PEER'
    }
]